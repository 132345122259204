import React from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const HomePage: React.FC = () => {

  const classes = useStyles()

  return (
    <div className={classes.content}>
        <div className={classes.centerText}>
            <Typography variant="h3" component="h1" className={classes.mobileTitle}>dev-resources.io</Typography>
            <Typography variant="h5" component="h5" className={classes.mobileBody}>Thank you for checking out the site. I hope that you find these resources helpful! If you feel like there's resources I should add. Feel free to contact me!</Typography>
            <Button variant="contained" color="primary" target="_blank" href="https://www.linkedin.com/in/vlastik-walker-57a229153/" className={classes.buttonSpacing}>
                Contact Me
            </Button>

            <div>
              <Typography variant="caption" >This site is owned by <a href="https://phxdevs.com/">PHX Devs LLC</a></Typography>
            </div>
        </div>
    </div>
  );
}

const useStyles = makeStyles(theme =>
  createStyles({
    centerText: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
      padding: '0 170px'
    },
    mobileTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '28px',
            fontWeight: '500'
        }
    },
    mobileBody: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px'
        }
    },
    content:  {
        height: '100vh',
        overflow: 'auto',
        marginTop: '-32px',
        marginBottom: '-32px',
        display: 'flex',
        alignItems: 'center'
    },
    buttonSpacing: {
        marginTop: '10px',
        marginBottom: '15px',
        color: '#f4f6ff',
        backgroundColor: '#5584ff',
        boxShadow: 'none'
    },
  }),
)

export default HomePage;