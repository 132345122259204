import React from 'react';
import clsx from 'clsx';
import { Switch, Route, Link, HashRouter  } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faHtml5, faCss3Alt, faJs } from '@fortawesome/free-brands-svg-icons';
import { faCode, faBookOpen, faTint, faFont, faIcons } from '@fortawesome/free-solid-svg-icons';

import ContentComponent from './components/ContentComponent';
import HomePage from './components/HomePage'

import htmlJson from './json/html.json';
import colorsJson  from  './json/colors.json';
import cssJson  from './json/css.json';
import iconsJson  from './json/icons.json';
import typoJson  from './json/typgraphy.json';
import javascriptJson  from './json/javascript.json';
import pythonJson  from './json/python.json';
import rubyJson  from './json/ruby.json';
import phpJson from './json/php.json';
import cplusJson from './json/cplus.json';
import csharpJson from './json/csharp.json';
import booksJson from './json/books.json';

import AppMenu from './components/AppMenu';

const PageDashboard = () => <HomePage/>
const PageHtml = () => <ContentComponent data={htmlJson} />
const PageCSS = () => <ContentComponent data={cssJson} />
const PageIcons = () => <ContentComponent data={iconsJson} />
const PageTypography = () => <ContentComponent data={typoJson} />
const PageColors = () => <ContentComponent data={colorsJson} />
const PageBooks = () => <ContentComponent data={booksJson} />
const PageJavascript = () => <ContentComponent data={javascriptJson} />
const PagePython = () => <ContentComponent data={pythonJson} />
const PageRuby = () => <ContentComponent data={rubyJson} />
const PagePhp = () => <ContentComponent data={phpJson} />
const PageCPlus = () => <ContentComponent data={cplusJson} />
const PageCSharp = () => <ContentComponent data={csharpJson} />

let extendSidebar = true;

if (window.innerWidth <= 768){
  extendSidebar = false
}

const App: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme();

  let [open, setOpen] = React.useState(extendSidebar);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const appColor = {
    backgroundColor: '#f6f7fa',
    color: '#212121'
  }

  const iconColor = {
    color: '#3b60bb'
  }

  return (
    <HashRouter>
      <div style={appColor} className={clsx('App', classes.root)}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              style={iconColor}
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}>
              <MenuIcon />
            </IconButton>

            <div className={clsx(classes.menuButton, {
            [classes.hide]: !open,
          })}>
            <Typography variant="h6" noWrap>
              <Link to="/" className={classes.brand}>dev-resources.io</Link>
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon style={iconColor} /> : <ChevronLeftIcon style={iconColor} />}
            </IconButton>
          </div>
          </Toolbar>
          <Divider/>

          <AppMenu />

        </Drawer>
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>

            <Switch>
              <Route path="/" exact component={PageDashboard} />
              <Route path="/html" component={PageHtml} />
              <Route path="/css" component={PageCSS} />
              <Route path="/icons" component={PageIcons} />
              <Route path="/typography" component={PageTypography} />
              <Route path="/colors" component={PageColors} />
              <Route path="/books" component={PageBooks} />
              <Route path="/javascript" component={PageJavascript} />
              <Route path="/python" component={PagePython} />
              <Route path="/ruby" component={PageRuby} />
              <Route path="/php" component={PagePhp} />
              <Route path="/c++" component={PageCPlus} />
              <Route path="/csharp" component={PageCSharp} />
            </Switch>

          </Container>
        </main>
      </div>
    </HashRouter>
  )
}

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#0e1a35',
    color: '#dfe0e2',
  },
  menuButton: {
    display: 'flex',
    alignItems: 'center'
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  brand: {
    textDecoration: 'none',
    color: '#dfe0e2'
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    height: '100vh',
    textAlign: 'center'
  }
}))

library.add(fab, faHtml5, faCode, faCss3Alt, faBookOpen, faTint, faFont, faIcons, faJs)

export default App