import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppMenuItemComponent from './AppMenuItemComponent'

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  devicon: PropTypes.string,
  fontawesome: PropTypes.any,
  items: PropTypes.array
}

type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>

export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[]
}

const AppMenuItem: React.FC<AppMenuItemProps> = props => {
  const { name, devicon, fontawesome, link, items = [] } = props
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }


  const MenuItemRoot = (

    <AppMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
      {/* Display an fontawesome icon if any */}
      {!!fontawesome && (
        <ListItemIcon className={classes.menuItemIcon + ' ' + classes.menuItemPadding}>
            <FontAwesomeIcon icon={fontawesome.icon} size={fontawesome.size} />
        </ListItemIcon>
      )}

      {/* Display an devicon icon if any */}  
      {!!devicon && (
        <ListItemIcon className={classes.menuItemIcon}>
           <i className={devicon+ ' ' + classes.menuItemIcon + ' ' + classes.iconSize} />
        </ListItemIcon>
      )}

      {fontawesome ? (
         <ListItemText primary={name} inset={!fontawesome} />
      ) : (
         <ListItemText primary={name} inset={!devicon} />
      )}
    
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore className={classes.menuItemIcon} />}
      {isExpandable && open && <IconExpandLess className={classes.menuItemIcon} />}
    </AppMenuItemComponent>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding >
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

AppMenuItem.propTypes = AppMenuItemPropTypes

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {},
    menuItemIcon: {
      color: '#3b60bb',
    },
    menuItemPadding: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px',
      },
      paddingLeft: '8px'
    },
    iconSize: {
      fontSize: '2em',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-7px',
      },
      paddingLeft: '5px'
    },
    childItem: {
      paddingLeft: '12px'
    }
  }),
)

export default AppMenuItem
