import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"


export interface ContentComponentProps {
  data?: any
}

const ContentComponent: React.FC<ContentComponentProps> = props => {
  const { data } = props

  const classes = useStyles()

  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <Grid container spacing={6} justify="center">
        {data.map(post => (
          <Grid item key={post.title} xs={12} sm={5} xl={4}>
            <Card className={classes.card}>
              <CardActionArea target="_blank" href={post.url}>
                <CardMedia
                  component="img"
                  alt={post.title}
                  height="200"
                  className={classes.media}
                  src={post.image}
                />
                <CardContent className={classes.cardBackground}>
                  <Typography className={classes.cardPrimaryText} gutterBottom variant="h6" component="h2">
                    {post.title}
                  </Typography>
                  <Typography className={classes.cardSecondaryText} component="p">{post.content}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      maxWidth: 345,
    },
    cardBackground: {
      background: '#fff',
    },
    cardPrimaryText: {
      color: '#040614'
    },
    cardSecondaryText: {
      color: '#505768'
    },
    media: {
    }
  }),
)

export default ContentComponent;