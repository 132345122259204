import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import AppMenuItem from './AppMenuItem'

const appMenuItems = [
  {
    name: 'Languages',
    fontawesome: {
      icon: 'code',
      size: 'lg'
    },
    items: [
      {
        name: 'JavaScript',
        link: '/javascript',
        fontawesome: {
          icon: ['fab', 'js'],
          size: '2x'
        }
      },
      {
        name: 'Python',
        link: '/python',
        devicon: 'devicon-python-plain'
      },
      {
        name: 'Ruby',
        link: '/ruby',
        devicon: 'devicon-ruby-plain'
      },
      {
        name: 'PHP',
        link: '/php',
        devicon: 'devicon-php-plain'
      },
      {
        name: 'C++',
        link: '/c++',
        devicon: 'devicon-cplusplus-plain'
      },
      {
        name: 'C#',
        link: '/csharp',
        devicon: 'devicon-csharp-plain'
      },
    ],
  },
  {
    name: 'HTML',
    link: '/html',
    fontawesome: {
      icon: ['fab', 'html5'],
      size: '2x'
    },
  },
  {
    name: 'CSS',
    link: '/css',
    fontawesome: {
      icon: ['fab', 'css3-alt'],
      size: '2x'
    },
  },
  {
    name: 'Icons',
    link: '/icons',
    fontawesome: {
      icon: 'icons',
      size: 'lg'
    },
  },
  {
    name: 'Typography',
    link: '/typography',
    fontawesome: {
      icon: 'font',
      size: 'lg'
    },
  },
  {
    name: 'Colors',
    link: '/colors',
    fontawesome: {
      icon: 'tint',
      size: 'lg'
    },
  },
  {
    name: 'Books',
    link: '/books',
    fontawesome: {
      icon: 'book-open',
      size: 'lg'
    },
  },
]

const AppMenu: React.FC = () => {
  const classes = useStyles()

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>
  )
}

const drawerWidth = 240


const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#3b60bb',
    },
  }),
)


export default AppMenu
